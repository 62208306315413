import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import rightStroke from '../Images/Icons/rightStroke.svg'
import viewMoreGREEN from '../Images/Icons/viewMore-GREEN.svg'
import affiliateWhite from '../Images/affiliateWhite.svg'

import campos from '../Images/BenefitsImages/campo.webp'
import optica from '../Images/Óptica/optica.png';
import ortopedia from '../Images/BenefitsImages/ortopedia.webp'
import farmRamos from '../Images/Farmacias/farmRAM-OR.webp'
import cooperativa from '../Images/BenefitsImages/cooperativa2001.webp'
import casamiento from '../Images/BenefitsImages/casamiento.webp'
import escolaridad from '../Images/BenefitsImages/escolaridad.webp'
import nacimiento from '../Images/BenefitsImages/nacimiento.webp'
import construccion from '../Images/BenefitsImages/construccion.webp'
import marquez from '../Images/BenefitsImages/grupoMarquez.webp'
import hoteles from '../Images/BenefitsImages/hoteles.webp'

const Benefits = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000, 
        });
      }, []); 

  return (
    <div>
        <body className="font-poppins p-6">
            <main className="container mx-auto" data-aos="fade-up">
                <h2 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">
                    Beneficios
                </h2>
                <p className="mb-[2rem] text-[#808080] text-[1.5rem] md:text-[2rem] font-medium">Amecys le da a sus afiliados los mejores beneficios en Salud, Turismo, Trabajo y Sucursales.</p>

                <section className="py-3" data-aos="fade-up">
                    <div className="grid-cols-1 mb-8 grid gap-4 md:grid-cols-2">
                        <div className="rounded-[1.2rem] h-[45rem] flex flex-col bg-white shadow-2xl">
                        <div className="flex-1 rounded-t-[1.2rem] relative overflow-hidden">
                                <img src={hoteles} className="h-full w-full object-cover"/>
                            </div>
                            <div className="p-6">
                                <h3 className="text-[#3B832F] text-[2rem] font-bold">Turismo y Vacaciones</h3>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="mb-[1rem] mt-[1rem] text-[#808080] text-[1.5rem] font-medium">Vacacioná en Cordóba o Santa Teresita.</p>
                                </div>
                                <div className="flex">
                                <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="text-[#808080] text-[1.5rem] font-medium">Financia con recibo de haberes o tarjeta de crédito.</p>
                                </div>
                                <div className="mt-[1rem] flex gap-x-4">
                                    <Link to="/Affiliates" className="text-[1.2rem] w-[15rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                        <img src={affiliateWhite} className="mr-[1rem] h-7 w-7 transform transition-transform hover:scale-110"/>
                                        ¡Quiero afiliarme!
                                    </Link>
                                    <Link to="/Tourism" className="text-[1.2rem] text-[#3B832F] border-2 border-[#3B832F] px-4 rounded-[0.7rem] flex transform items-center text-left font-medium transition-transform hover:scale-105">
                                        Ver más
                                        <img src={viewMoreGREEN} alt="Ver más" className="ml-[1rem] h-4 w-4 md:h-5 md:w-5 transform transition-transform hover:scale-110"/>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-[1.2rem] h-[45rem] flex flex-col bg-white shadow-2xl">
                            <div className="flex-1 rounded-t-[1.2rem] relative overflow-hidden">
                                <img src={campos} className="h-full w-full object-cover"/>
                            </div>
                            <div className="p-6">
                                <h3 className="text-[#3B832F] text-[2rem] font-bold">Campos recreativos</h3>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="mb-[1rem] mt-[1rem] text-[#808080] text-[1.5rem] font-medium">2 campos para disfrutar el día en familia.</p>
                                </div>
                                <div className="flex">
                                <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="text-[#808080] text-[1.5rem] font-medium">Ingreso sin cargo para afiliados activos.</p>
                                </div>
                                <div className="justify-content-start mt-[1rem] flex">
                                    <Link to="/Affiliates" className="text-[1.2rem] w-[15rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                        <img src={affiliateWhite} className="mr-[1rem] h-7 w-7 transform transition-transform hover:scale-110"/>
                                        ¡Quiero afiliarme!
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-3" data-aos="fade-up">
                    <div className="grid-cols-1 mb-8 grid gap-4 md:grid-cols-2">
                        <div className="rounded-[1.2rem] h-[45rem] flex flex-col bg-white shadow-2xl">
                            <div className="flex-1 rounded-t-[1.2rem] relative flex items-center justify-center overflow-hidden">
                                <img src={optica} className="h-full w-full"/>
                            </div>
                            <div className="p-6">
                                <h3 className="text-[#3B832F] text-[2rem] font-bold">Óptica de Amecys</h3>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="mb-[1rem] mt-[1rem] text-[#808080] text-[1.5rem] font-medium">¡Encontra descuentos de hasta 25%!</p>
                                </div>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="text-[#808080] text-[1.5rem] font-medium">Financia con recibo de haberes o tarjeta de crédito.</p>
                                </div>
                                <div className="mt-[1rem] flex gap-x-4">
                                    <Link to="/Affiliates" className="text-[1.2rem] w-[15rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                        <img src={affiliateWhite} className="mr-[1rem] h-7 w-7 transform transition-transform hover:scale-110"/>
                                        ¡Quiero afiliarme!
                                    </Link>
                                    <Link to="/Optics" className="text-[1.2rem] text-[#3B832F] border-2 border-[#3B832F] px-4 rounded-[0.7rem] flex transform items-center text-left font-medium transition-transform hover:scale-105">
                                        Ver más
                                        <img src={viewMoreGREEN} alt="Ver más" className="ml-[1rem] h-4 w-4 md:h-5 md:w-5 transform transition-transform hover:scale-110"/>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-[1.2rem] h-[45rem] flex flex-col bg-white shadow-2xl">
                            <div className="flex-1 rounded-t-[1.2rem] relative overflow-hidden">
                                <img src={ortopedia} className="h-full w-full object-cover"/>
                            </div>
                            <div className="p-6">
                                <h3 className="text-[#3B832F] text-[2rem] font-bold">Ortopedia</h3>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="mb-[1rem] mt-[1rem] text-[#808080] text-[1.5rem] font-medium">Te apoyamos en la compra elementos de ortopedia.</p>
                                </div>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="text-[#808080] text-[1.5rem] font-medium">Financia con recibo de haberes o tarjeta de crédito.</p>
                                </div>
                                <div className="justify-content-start mt-[1rem] flex">
                                    <Link to="/Affiliates" className="text-[1.2rem] w-[15rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                        <img src={affiliateWhite} className="mr-[1rem] h-7 w-7 transform transition-transform hover:scale-110"/>
                                        ¡Quiero afiliarme!
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-3" data-aos="fade-up">
                    <div className="grid-cols-1 mb-8 grid gap-4 md:grid-cols-2">
                        <div className="rounded-[1.2rem] h-[45rem] flex flex-col bg-white shadow-2xl">
                            <div className="flex-1 rounded-t-[1.2rem] relative overflow-hidden">
                                <img src={farmRamos} className="h-full w-full object-cover"/>
                            </div>
                            <div className="p-6">
                                <h3 className="text-[#3B832F] text-[2rem] font-bold">Farmacias</h3>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="mb-[1rem] mt-[1rem] text-[#808080] text-[1.5rem] font-medium">Descuentos en todas nuestras farmacias para afiliados.</p>
                                </div>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="text-[#808080] text-[1.5rem] font-medium">¡Si sos afiliado visitanos en cualquier sucursal!</p>
                                </div>
                                <div className="mt-[1rem] flex gap-x-4">
                                    <Link to="/Affiliates" className="text-[1.2rem] w-[15rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                        <img src={affiliateWhite} className="mr-[1rem] h-7 w-7 transform transition-transform hover:scale-110"/>
                                        ¡Quiero afiliarme!
                                    </Link>
                                    <Link to="/Pharmacy" className="text-[1.2rem] text-[#3B832F] border-2 border-[#3B832F] px-4 rounded-[0.7rem] flex transform items-center text-left font-medium transition-transform hover:scale-105">
                                        Ver más
                                        <img src={viewMoreGREEN} alt="Ver más" className="ml-[1rem] h-4 w-4 md:h-5 md:w-5 transform transition-transform hover:scale-110"/>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-[1.2rem] h-[45rem] flex flex-col bg-white shadow-2xl">
                            <div className="flex-1 rounded-t-[1.2rem] relative flex items-center justify-center overflow-hidden">
                                <img src={cooperativa} className="h-[25rem] w-[25rem]"/>
                            </div>
                            <div className="p-6">
                                <h3 className="text-[#3B832F] text-[2rem] font-bold">Ayuda económica</h3>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="mb-[1rem] mt-[1rem] text-[#808080] text-[1.5rem] font-medium">La <b>Cooperativa 2001</b> otorga créditos a nuestros afiliados.</p>
                                </div>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="text-[#808080] text-[1.5rem] font-medium">Los créditos serían depositados a tu propia cuenta bancaria.</p>
                                </div>
                                <div className="justify-content-start mt-[1rem] flex">
                                    <Link to="/Affiliates" className="text-[1.2rem] w-[15rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                        <img src={affiliateWhite} className="mr-[1rem] h-7 w-7 transform transition-transform hover:scale-110"/>
                                        ¡Quiero afiliarme!
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-3" data-aos="fade-up">
                    <div className="grid-cols-1 mb-8 grid gap-4 md:grid-cols-2">
                        <div className="rounded-[1.2rem] h-[45rem] flex flex-col bg-white shadow-2xl">
                            <div className="flex-1 rounded-t-[1.2rem] relative overflow-hidden">
                                <img src={casamiento} className="h-full w-full object-cover"/>
                            </div>
                            <div className="p-6">
                                <h3 className="text-[#3B832F] text-[2rem] font-bold">Casamiento</h3>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="mb-[1rem] mt-[1rem] text-[#808080] text-[1.5rem] font-medium">Te damos un salario extra por consumar tu casamiento.</p>
                                </div>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="text-[#808080] text-[1.5rem] font-medium">El beneficio es un porcetaje de antiguedad de afiliación.</p>
                                </div>
                                <div className="justify-content-start mt-[1rem] flex">
                                    <Link to="/Affiliates" className="text-[1.2rem] w-[15rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                        <img src={affiliateWhite} className="mr-[1rem] h-7 w-7 transform transition-transform hover:scale-110"/>
                                        ¡Quiero afiliarme!
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-[1.2rem] h-[45rem] flex flex-col bg-white shadow-2xl">
                            <div className="flex-1 rounded-t-[1.2rem] relative overflow-hidden">
                                <img src={escolaridad} className="h-full w-full object-cover"/>
                            </div>
                            <div className="p-6">
                                <h3 className="text-[#3B832F] text-[2rem] font-bold">Escolaridad</h3>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="mb-[1rem] mt-[1rem] text-[#808080] text-[1.5rem] font-medium">Todos los años te damos un kit de útiles.</p>
                                </div>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="text-[#808080] text-[1.5rem] font-medium">Desde jardin de 3 años hasta 1er año de secundaria.</p>
                                </div>
                                <div className="justify-content-start mt-[1rem] flex">
                                    <Link to="/Affiliates" className="text-[1.2rem] w-[15rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                        <img src={affiliateWhite} className="mr-[1rem] h-7 w-7 transform transition-transform hover:scale-110"/>
                                        ¡Quiero afiliarme!
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-3" data-aos="fade-up">
                    <div className="grid-cols-1 mb-8 grid gap-4 md:grid-cols-2">
                        <div className="rounded-[1.2rem] h-[45rem] flex flex-col bg-white shadow-2xl">
                            <div className="flex-1 rounded-t-[1.2rem] relative overflow-hidden">
                                <img src={nacimiento} className="h-full w-full object-cover"/>
                            </div>
                            <div className="p-6">
                                <h3 className="text-[#3B832F] text-[2rem] font-bold">Nacimiento</h3>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="mb-[1rem] mt-[1rem] text-[#808080] text-[1.5rem] font-medium">Te damos una silla de comer y una pelela musical.</p>
                                </div>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="text-[#808080] text-[1.5rem] font-medium">Solo para afiliados con más de 6 meses de antigüedad. </p>
                                </div>
                                <div className="justify-content-start mt-[1rem] flex">
                                    <Link to="/Affiliates" className="text-[1.2rem] w-[15rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                        <img src={affiliateWhite} className="mr-[1rem] h-7 w-7 transform transition-transform hover:scale-110"/>
                                        ¡Quiero afiliarme!
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-[1.2rem] h-[45rem] flex flex-col bg-white shadow-2xl">
                            <div className="flex-1 rounded-t-[1.2rem] relative overflow-hidden">
                                <img src={construccion} className="h-full w-full object-cover"/>
                            </div>
                            <div className="p-6">
                                <h3 className="text-[#3B832F] text-[2rem] font-bold">Construcción</h3>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="mb-[1rem] mt-[1rem] text-[#808080] text-[1.5rem] font-medium">El corralon <b>Una Construcción</b> ayuda a los afiliados.</p>
                                </div>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="text-[#808080] text-[1.5rem] font-medium">Financia tus gastos con tu recibo de haberes de Amecys.</p>
                                </div>
                                <div className="justify-content-start mt-[1rem] flex">
                                    <Link to="/Affiliates" className="text-[1.2rem] w-[15rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                        <img src={affiliateWhite} className="mr-[1rem] h-7 w-7 transform transition-transform hover:scale-110"/>
                                        ¡Quiero afiliarme!
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-3" data-aos="fade-up">
                    <div className="mb-[6rem] flex justify-center">
                        <div className="rounded-[1.2rem] w-[48rem] h-[46rem] flex flex-col bg-white shadow-2xl">
                            <div className="flex-1 rounded-t-[1.2rem] relative overflow-hidden">
                                <img src={marquez} className="h-full w-full object-cover"/>
                            </div>
                            <div className="p-6">
                                <h3 className="text-[#3B832F] text-[2rem] font-bold">Proveeduria</h3>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="mb-[1rem] mt-[1rem] text-[#808080] text-[1.5rem] font-medium">El <b>Grupo Marquez</b> otorga electrodomésticos a nuestros afiliados.</p>
                                </div>
                                <div className="flex">
                                    <img src={rightStroke} className="mr-[1rem]"/>
                                    <p className="text-[#808080] text-[1.5rem] font-medium">Financia tus gastos con tu recibo de haberes de Amecys.</p>
                                </div>
                                <div className="justify-content-start mt-[1rem] flex">
                                    <Link to="/Affiliates" className="text-[1.2rem] w-[15rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                        <img src={affiliateWhite} className="mr-[1rem] h-7 w-7 transform transition-transform hover:scale-110"/>
                                        ¡Quiero afiliarme!
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </body>
    </div>
  );
};

export default Benefits;