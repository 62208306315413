import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import rightStroke from '../Images/Icons/rightStroke.svg'
import Ledesma from '../Images/LEDESMA.webp'
import Luduena from '../Images/LUDUENA.webp'
import Gardella from '../Images/GARDELLA.webp'
import Sotelo from '../Images/SOTELO.webp'

const Authorities = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000, 
        });
      }, []); 

  return (
    <div>
        <body className="font-poppins p-6">
    <main className="container mx-auto">
        <section className="px-8 py-3" data-aos="fade-up">
            <h1 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">
                Autoridades
            </h1>
            <div className="mb-[2rem] flex">
                <img src={rightStroke} className="mr-[1rem]"/>
                <p className="text-[#3B832F] text-[1.5rem] md:text-[2rem] font-semibold italic">Comisión Directiva - Período <span className='font-bold'>2022-2026</span></p>
            </div>
            
            <div className="grid-cols-1 grid gap-4 md:grid-cols-4">

                <div className="h-[35rem] rounded-[1.2rem] flex w-full flex-col justify-end overflow-hidden bg-white text-center shadow-2xl">
                    <div className="flex-1 relative w-full">
                        <img src={Ledesma} className="h-full w-full object-cover"/>
                    </div>
                    <div className="px-4 pb-4 pt-3">
                        <h3 className="text-[1.5rem] pt-3 font-semibold">Julio Ruben Ledesma</h3>
                        <p className="mb-[1rem] text-[1.2rem] text-[#3B832F] font-medium">Presidente de <span className="text-[#3B832F] text-[1.2rem] font-bold">Amecys</span></p>
                    </div>
                </div>

                <div className="h-[35rem] rounded-[1.2rem] flex w-full flex-col justify-end overflow-hidden bg-white text-center shadow-2xl">
                    <div className="flex-1 relative w-full">
                        <img src={Luduena} className="h-full w-full object-cover"/>
                    </div>
                    <div className="px-4 pb-4 pt-3">
                        <h3 className="text-[1.5rem] pt-3 font-semibold">Ernesto Ludueña</h3>
                        <p className="mb-[1rem] text-[1.2rem] text-[#3B832F] font-medium">Vicepresidente de <span className="text-[#3B832F] text-[1.2rem] font-bold">Amecys</span></p>
                    </div>
                </div>

                <div className="h-[35rem] rounded-[1.2rem] flex w-full flex-col justify-end overflow-hidden bg-white text-center shadow-2xl">
                    <div className="flex-1 relative w-full">
                        <img src={Gardella} className="h-full w-full object-cover"/>
                    </div>
                    <div className="px-4 pb-4 pt-3">
                        <h3 className="text-[1.5rem] pt-3 font-semibold">Patricia Gardella</h3>
                        <p className="mb-[1rem] text-[1.2rem] text-[#3B832F] font-medium">Secretaria de <span className="text-[#3B832F] text-[1.2rem] font-bold">Amecys</span></p>
                    </div>
                </div>

                <div className="h-[35rem] rounded-[1.2rem] flex w-full flex-col justify-end overflow-hidden bg-white text-center shadow-2xl">
                    <div className="flex-1 relative w-full">
                        <img src={Sotelo} className="h-full w-full object-cover"/>
                    </div>
                    <div className="px-4 pb-4 pt-3">
                        <h3 className="text-[1.5rem] pt-3 font-semibold">Juan Andrés Sotelo</h3>
                        <p className="mb-[1rem] text-[1.2rem] text-[#3B832F] font-medium">Tesorero de <span className="text-[#3B832F] text-[1.2rem] font-bold">Amecys</span></p>
                    </div>
                </div>
            </div>
        </section>

        <section className="px-8 py-3 mt-[5rem]" data-aos="fade-up">
            <h2 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">
                Vocales
            </h2>
            <p className="mb-[2rem] text-[#808080] text-[1.5rem] md:text-[2rem] font-medium">PRO Secretario, PRO Tesorero y Vocales Titulares y Suplentes.</p>
            <hr className="mb-[2rem]"/>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            <div className="w-full md:w-2/3">
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-bold">PRO Secretario:</span> Gonzalez, Carlos Humberto</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-bold">PRO Tesorero:</span> Ledesma, Fabricio</p>
                </div>
                
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-bold">Vocal Titular 1ero:</span> Colpachy, Leonardo Angel</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-bold">Vocal Titular 2do:</span> Giffoni, Juan Carlos</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-bold">Vocal Titular 3ero:</span> Chavez, Basilia Adelaida</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-bold">Vocal Titular 4to:</span> Ludueña, Eduardo Raúl</p>
                </div>
                <div className="flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-regular">Vocal Suplente 1ero:</span> Perez, Óscar Mauro del Rosario</p>
                </div>
                </div>

                <div className="w-full md:w-2/3">
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-regular">Vocal Suplente 2do:</span> Palacios, Alfredo Guillermo</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-regular">Vocal Suplente 3ero:</span> Companucci, Gabriel Hernan</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-regular">Vocal Suplente 4to:</span> Stazone, Carla Antonela</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-regular">Vocal Suplente 5to:</span> Cabrera, Alejandro Alfredo</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-regular">Vocal Suplente 6to:</span> Varela, Natalia Soledad</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-regular">Vocal Suplente 7mo:</span> Gonzalez, Juan José</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-regular">Vocal Suplente 8vo:</span> Ares, Mateo Gabriel</p>
                </div>
            </div>
            </div>
        </section>


        <section className="px-8 py-3 mt-[1rem] mb-[5rem]" data-aos="fade-up">
            <h2 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">
                Junta Fiscalizadora
            </h2>
            <p className="mb-[2rem] text-[#808080] text-[1.5rem] md:text-[2rem] font-medium">Fiscales Titulares y Suplentes.</p>
            <hr className="mb-[2rem]"/>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            <div className="w-full md:w-2/3">
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-bold">Titular 1ero:</span> Loza, Raúl Rene</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-bold">Titular 2do:</span> Perez, Daniel Gilberto</p>
                </div>
                <div className="flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-bold">Titular 3ero:</span> Marquez, Maria Laura</p>
                </div>
                </div>

                <div className="w-full md:w-2/3">
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-regular">Suplente 1ero:</span> Correa, Jorge Helio</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-regular">Suplente 2do:</span> Esandi, María Elena</p>
                </div>
                <div className="mb-4 flex">
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[1.5rem] font-semibold text-[#808080]"><span className="text-[#3B832F] text-[1.5rem] font-regular">Suplente 3ero:</span> Alvarez, Karina Griselda</p>
                </div>
            </div>
            </div>
        </section>
    </main>
</body>
    </div>
  );
};

export default Authorities;
