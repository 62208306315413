import React, { useEffect } from 'react';
import rightStroke from '../../Images/Icons/rightStroke.svg'
import AOS from 'aos';
import 'aos/dist/aos.css'; 


const Institutions = () => {
    
    useEffect(() => {
        AOS.init({
          duration: 1000, 
        });
      }, []); 

  return (
    <div>
        <body className="font-poppins p-6">
        <main className="container mx-auto">
            <section className="py-3 mb-[8rem]" data-aos="fade-up">
            <h1 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">
                Convenios Institucionales
            </h1>
            <p className="mb-[2rem] text-[#808080] text-[1.5rem] md:text-[2rem] font-medium">
                Estas son todas las instituciones que confían y disfrutan de los beneficios de Amecys.
            </p>
            <hr className="mb-[2rem] mt-[1rem]" />
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="w-full md:w-2/3">
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">Unión de Empleados de la Justicia de la Nación (UEJN)</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">OSDOP</p>
                </div>
            </div>

            <div className="w-full md:w-2/3">
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">ASSIMRA</p>
                </div>
            </div>

            <div className="w-full md:w-2/3">
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SADOP</p>
                </div>
            </div>
        </div>
     </section>
        </main>
    </body>
    </div>
  );
};

export default Institutions;
