import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import optica from '../../Images/Óptica/optica.png';
import arm from '../../Images/Óptica/arm.webp';
import ubiIcon from '../../Images/Icons/ubiICON.svg'

const Carousel = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000, 
        });
      }, []); 

    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);

    const slides = [
        {
            image: optica,
            title: '¡Óptica propia!',
            description: 'Te esperamos.',
            button: true, 
        },
        {
            image: arm,
            title: 'Equipo de ARM especializado',
            description: 'Tenemos consultorios equipados.',
            button: false, 
        },
    ];


    const nextImage = () => {
        setFade(false); 
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
            setFade(true);
        }, 300); 
    };


    const prevImage = () => {
        setFade(false); 
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
            setFade(true); 
        }, 300); 
    };

    return (
        <div className="relative container mx-auto">
            <div className="overflow-hidden rounded-2xl relative">
                <img 
                    src={slides[currentIndex].image} 
                    alt={`Slide ${currentIndex + 1}`} 
                    className={`w-full h-[32rem] md:h-[33rem] object-cover object-center transition-opacity duration-500 ease-in-out ${fade ? 'opacity-100' : 'opacity-0'}`}
                />
                <div className="absolute inset-0 bg-black opacity-30"></div>

                <div className="absolute inset-y-0 left-0 flex items-center pl-[5rem] md:pl-[8rem] lg:pl-[8rem]" data-aos="fade-up">
                    <div className="text-left max-w-sm lg:max-w-sm">
                        <h3 className="text-3xl md:text-5xl lg:text-5xl text-white pb-3 pt-3 font-extrabold leading-tight">
                            {slides[currentIndex].title}
                        </h3>
                        <p className="mb-2 text-xl md:text-2xl lg:text-2xl text-[#91FF80] pb-3 font-semibold">
                            {slides[currentIndex].description}
                        </p>
                        {slides[currentIndex].button && (
                            <button className="text-sm md:text-base bg-[#3B832F] px-4 py-2 rounded-md relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                                <img src={ubiIcon} className="mr-2 transition-transform hover:scale-110" alt="Ubicación Icono" />
                                Ver mapa
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <button 
                onClick={prevImage} 
                className="absolute top-1/2 left-4 md:left-8 transform -translate-y-1/2 p-2 bg-[#3B832F] bg-opacity-50 rounded-full text-white w-10 h-10 flex items-center justify-center"
            >
                &#10094;
            </button>
            <button 
                onClick={nextImage} 
                className="absolute top-1/2 right-4 md:right-8 transform -translate-y-1/2 p-2 bg-[#3B832F] bg-opacity-50 rounded-full text-white w-10 h-10 flex items-center justify-center"
            >
                &#10095;
            </button>

            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {slides.map((_, index) => (
                    <div 
                        key={index} 
                        className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-white' : 'bg-gray-400'}`} 
                    />
                ))}
            </div>
        </div>

    );
};

export default Carousel;
