import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import rightStroke from '../Images/Icons/rightStroke.svg'
import ubiIcon from '../Images/Icons/ubiICON-GREEN.svg'
import leftStroke from '../Images/Icons/fullLeftStroke-GREEN.svg'

import hotelRel from '../Images/ElReloj/hotel.webp'
import camasRel from '../Images/ElReloj/camas.webp'
import camaMatriRel from '../Images/ElReloj/camaMatrimonial.webp'
import desayunoRel from '../Images/ElReloj/desayuno.webp'
import entradaRel from '../Images/ElReloj/entrada.webp'

import hotelRumi from '../Images/Rumipal/hotel.webp'
import habitacionRumi from '../Images/Rumipal/habitacion.webp'
import interiorRumi from '../Images/Rumipal/interior.webp'
import piletaRumi from '../Images/Rumipal/pileta.webp'
import comedorRumi from '../Images/Rumipal/comedor.webp'

function Tourism() {
    useEffect(() => {
        AOS.init({
          duration: 1000, 
        });
      }, []); 

      const [mainImageRel, setMainImageRel] = useState(hotelRel); 
      const [smallImagesRel, setSmallImagesRel] = useState([entradaRel, desayunoRel, camasRel, camaMatriRel]);

      const handleImageClickRel = (clickedImageRel) => {
        const newSmallImagesRel = smallImagesRel.map(imgRel => imgRel === clickedImageRel ? mainImageRel : imgRel);
        setMainImageRel(clickedImageRel); 
        setSmallImagesRel(newSmallImagesRel); 
      };

      const [mainImageRumi, setMainImageRumi] = useState(interiorRumi); 
      const [smallImagesRumi, setSmallImagesRumi] = useState([hotelRumi, comedorRumi, habitacionRumi, piletaRumi]);

      const handleImageClickRumi = (clickedImageRumi) => {
        const newSmallImagesRumi = smallImagesRumi.map(imgRumi => imgRumi === clickedImageRumi ? mainImageRumi : imgRumi);
        setMainImageRumi(clickedImageRumi); 
        setSmallImagesRumi(newSmallImagesRumi); 
      };
      

  return (
    <div>
        <body class="font-poppins p-6">
            <main class="container mx-auto mt-8" data-aos="fade-up">
                <Link to="/Benefits" class="text-[1.5rem] w-[20rem] relative flex transform items-center font-regular text-[#3B832F] transition-transform hover:scale-105">
                    <img src={leftStroke} class="mr-[1rem] h-6 w-6"/>
                    Volver a Beneficios
                </Link>

            <section class="mb-[4rem]" data-aos="fade-up">
            <h1 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">
                Santa Teresita 
            </h1>
            <div className="mb-[2rem] flex">
                <img src={rightStroke} className="mr-[1rem]"/>
                <p className="text-[#3B832F] text-[1.5rem] font-semibold italic">Hotel <span className='font-bold'>"El Reloj"</span></p>
            </div>
            <hr className="mb-[2rem]"/>
            <div className="flex flex-col w-full lg:flex-row">
                <div className="lg:mr-4 w-full lg:w-2/3 mb-6">
                    <img 
                    src={mainImageRel} 
                    alt="Imágen" 
                    className="w-[55rem] h-[40rem] object-cover rounded-lg shadow-2xl" 
                    />
                </div>

                <div className="grid grid-cols-2 gap-4 w-full lg:w-2/4 h-[40rem]">
                    {smallImagesRel.map((imageRel, index) => (
                    <img
                        key={index}
                        src={imageRel}
                        alt={`Imágenes ${index + 1}`}
                        className="w-full h-full object-cover rounded-lg shadow-2xl aspect-[1/1] cursor-pointer"
                        onClick={() => handleImageClickRel(imageRel)}
                    />
                    ))}
                </div>
            </div>


            <div data-aos="fade-up">
                <div className='flex mt-[2rem]'>
                    <img src={ubiIcon} className="mr-[1rem] w-8"/>
                    <h1 className="text-[2.5rem] text-[#3B832F] flex items-center font-bold">
                        El Reloj
                    </h1>
                </div>
                    <p className="text-[1.5rem] text-[#808080] flex items-center font-medium mb-8">
                        La Asociación Mutual de Empleados de Comercio y Servicios es la encargada de la administración del Hotel "El Reloj", propiedad del Sindicato de Empleados y Obreros de Comercio y Afines.
                        Este hotel esta ubicado en la Costa Atlántica específicamente en Santa Teresita, en la calle 39 Nº 250 a 50 mts de la peatonal y 150 mts del mar, desembocando en el típico barco de la costa.
                    </p>

                    <ul class="space-y-2 text-[1.5rem]">
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular"><strong class="text-[#3B832F]">Ubicación:</strong> Calle 39 Nº 250 a 50 mts. de la peatonal y 150 mts. del mar.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">13 habitaciones con TV Smart 32" y caja de seguridad (con Wi-Fi).</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Baños privados con ventilación y secador de pelo.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Sommier´s matrimoniales e individuales.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Servicio de blanqueria.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Servicio de desayuno Americano libre y cena.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Wi-Fi en todo el hotel.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Alquiler de accesorios para la playa y atención las 24hs.</li>
                        </div>
                    </ul>
                </div>
            </section>

            <section class="mb-[6rem]" data-aos="fade-up">
            <h1 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">
                Córdoba 
            </h1>
            <div className="mb-[2rem] flex">
                <img src={rightStroke} className="mr-[1rem]"/>
                <p className="text-[#3B832F] text-[1.5rem] font-semibold italic">Hotel <span className='font-bold'>"Rumipal Amecys"</span></p>
            </div>
            <hr className="mb-[2rem]"/>
            <div className="flex flex-col w-full lg:flex-row">
                <div className="lg:mr-4 w-full lg:w-2/3 mb-6">
                    <img 
                    src={mainImageRumi} 
                    alt="Imágen" 
                    className="w-[55rem] h-[40rem] object-cover rounded-lg shadow-2xl" 
                    />
                </div>

                <div className="grid grid-cols-2 gap-4 w-full lg:w-2/4 h-[40rem]">
                    {smallImagesRumi.map((imageRumi, index) => (
                    <img
                        key={index}
                        src={imageRumi}
                        alt={`Imágenes ${index + 1}`}
                        className="w-full h-full object-cover rounded-lg shadow-2xl aspect-[1/1] cursor-pointer"
                        onClick={() => handleImageClickRumi(imageRumi)}
                    />
                    ))}
                </div>
            </div>

            <div data-aos="fade-up">
                <div className='flex mt-[2rem]'>
                    <img src={ubiIcon} className="mr-[1rem] w-8"/>
                    <h1 className="text-[2.5rem] text-[#3B832F] flex items-center font-bold">
                        Rumipal Amecys
                    </h1>
                </div>
                    <p className="text-[1.5rem] text-[#808080] flex items-center font-medium mb-8">
                        La Asociación Mutual de Empleados de Comercio y Servicios es la encargada de la administración del Hotel "Rumipal Amecys", propiedad del Sindicato de Empleados y Obreros de Comercio y Afines.
                        Este hotel esta ubicado en la provincia de Córdoba específicamente en Villa Rumipal, en la calle Almirante Brown 246.
                    </p>

                    <ul class="space-y-2 text-[1.5rem]">
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular"><strong class="text-[#3B832F]">Ubicación:</strong> Almirante Brown 246, Villa Rumipal.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Habitaciones con TV Smart y caja de seguridad.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Baños privados con ventilación y secador de pelo.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Sommier´s matrimoniales e individuales.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Servicio de blanqueria.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Servicio de desayuno y cena.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Patio al aire libre con pileta.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Wi-Fi en todo el hotel.</li>
                        </div>
                        <div className='flex'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <li class="text-[#808080] font-regular">Cochera cubierta.</li>
                        </div>
                    </ul>
                </div>
            </section>
            </main>
        </body>
    </div>
  )
}

export default Tourism
