import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import rightStroke from '../Images/Icons/rightStroke.svg';
import leftStroke from '../Images/Icons/fullLeftStroke-GREEN.svg';
import wppIcon from '../Images/Icons/wppIcon.svg';
import Spinner from './utils/Spinner'; 

const AffiliatesForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        serviceType: '', 
    });

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');  

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Ingresa tu nombre completo.';
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Ingresa un correo válido.';
        if (!formData.phone || !/^\d{10}$/.test(formData.phone)) newErrors.phone = 'Ingresa un número de teléfono válido.';
        if (!formData.serviceType) newErrors.serviceType = 'Por favor selecciona un tipo de afiliación.';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsLoading(true);  

        try {
            const response = await fetch("https://amecys-backend.onrender.com/api/Affiliates", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccessMessage('*Formulario enviado correctamente');  // Establecer mensaje de éxito
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    serviceType: '',
                });
            } else {
                const errorData = await response.json();
                alert(`Hubo un problema al enviar el formulario: ${JSON.stringify(errorData)}`);
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
        } finally {
            setIsLoading(false); 
        }
    };

    return (
        <div>
            <body className="font-poppins p-6">
                <main className="container mx-auto mt-8" data-aos="fade-up">
                    <Link to="/Affiliates" className="text-[1.5rem] w-[20rem] relative flex transform items-center font-regular text-[#3B832F] transition-transform hover:scale-105">
                        <img src={leftStroke} className="mr-[1rem] h-6 w-6" />
                        Volver a Afiliación
                    </Link>

                    <section className="mb-[10rem] mt-[2rem]" data-aos="fade-up">
                        <div className="container mx-auto flex flex-col md:flex-row">
                            <div className="w-full md:w-1/2">
                                <h3 className="text-[3rem] md:text-[4rem] text-[#3B832F] mb-3 pt-3 font-extrabold leading-tight">
                                    Formulario de contacto
                                </h3>
                                <p className="text-[1.5rem] md:text-[2rem] text-[#808080] mb-6 font-medium">
                                    Completá y enviá este formulario para obtener más información de cómo afiliarte. Es importante que tus datos de contacto sean correctos, corroboralos antes de confirmar la operación.
                                </p>
                                <div className="mb-4 flex items-center">
                                    <img src={rightStroke} className="mr-[1rem]" />
                                    <p className="text-[#808080] text-[1.5rem] md:text-[2rem] font-bold">Completa los datos.</p>
                                </div>
                                <div className="mb-4 flex items-center">
                                    <img src={rightStroke} className="mr-[1rem]" />
                                    <p className="text-[#808080] text-[1.5rem] md:text-[2rem] font-bold">Envia el formulario.</p>
                                </div>
                                <div className="mb-4 flex items-center">
                                    <img src={rightStroke} className="mr-[1rem]" />
                                    <p className="text-[#808080] text-[1.5rem] md:text-[2rem] font-bold">Espera a que te contactemos.</p>
                                </div>
                            </div>

                            <div className="w-full md:w-1/2 md:ml-[8rem] mt-8 md:mt-0">
                                <div className={isMobile ? "relative pointer-events-none" : ""}>
                                    {isMobile && (
                                        <div className="absolute inset-0 rounded-3xl flex justify-center text-center items-center bg-black bg-opacity-70 text-[white] text-[1.5rem] font-semibold">
                                            <p className='bg-[#3B832F] p-3 m-12 rounded-xl'>Si estas desde tu celular, contactanos por <span className='font-light'>WhatsApp</span>.</p>
                                        </div>
                                    )}
                                <div className={`w-full ${Object.keys(errors).length > 0 ? 'min-h-[45rem]' : 'min-h-[40rem]'} bg-white shadow-xl border-[0.2rem] rounded-3xl p-10`}>
                                    <form className="space-y-12" onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="name" className="block font-semibold text-[1.5rem] text-[#3B832F]">Nombre completo</label>
                                            <input
                                                type="text"
                                                id="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                placeholder="Nombre/s Apellido/s"
                                                className="mt-1 w-full px-4 py-2 border text-[1.2rem] border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#3B832F] focus:border-[#3B832F]"
                                            />
                                            {errors.name && <p className="text-red-500">{errors.name}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block font-semibold text-[1.5rem] text-[#3B832F]">Correo electrónico</label>
                                            <input
                                                type="email"
                                                id="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                placeholder="correo@email.com"
                                                className="mt-1 w-full px-4 py-2 border text-[1.2rem] border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#3B832F] focus:border-[#3B832F]"
                                            />
                                            {errors.email && <p className="text-red-500">{errors.email}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="phone" className="block font-semibold text-[1.5rem] text-[#3B832F]">Número de teléfono</label>
                                            <input
                                                type="tel"
                                                id="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                placeholder="1133334444"
                                                className="mt-1 w-full px-4 py-2 border text-[1.2rem] border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#3B832F] focus:border-[#3B832F]"
                                            />
                                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="serviceType" className="block font-semibold text-[1.5rem] text-[#3B832F]">Tipo de afiliación</label>
                                            <select
                                                id="serviceType"
                                                value={formData.serviceType}
                                                onChange={handleChange}
                                                className="mt-1 w-full font-regular text-[1.2rem] px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#3B832F] focus:border-[#3B832F]"
                                            >
                                                <option value="">Selecciona una opción</option>
                                                <option className="font-semibold">Afiliado Activo</option>
                                                <option className="font-semibold">Afiliado Adherente</option>
                                                <option className="font-semibold">Empresa, Institución o Comercio</option>
                                            </select>
                                            {errors.serviceType && <p className="text-red-500">{errors.serviceType}</p>}
                                        </div>
                                        <div className="justify-center">
                                            <div>
                                                {successMessage && (
                                                    <p className="text-[#58b647] text-center text-[1.5rem] font-bold mb-2">
                                                        {successMessage}
                                                    </p>
                                                )}
                                            </div>
                                            <button
                                                type="submit"
                                                className="text-[1.3rem] bg-[#3B832F] px-[1rem] py-[0.7rem] w-full font-medium flex items-center justify-center rounded-[0.7rem] shadow-lg text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3B832F]"
                                                disabled={isLoading}
                                            >
                                                    {isLoading ? <Spinner /> : 'Enviar formulario'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                                {/* <div className={`w-full ${Object.keys(errors).length > 0 ? 'min-h-[45rem]' : 'min-h-[40rem]'} bg-white shadow-xl border-[0.2rem] rounded-3xl p-10`}>
                                    <form className="space-y-12" onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="name" className="block font-semibold text-[1.5rem] text-[#3B832F]">Nombre completo</label>
                                            <input
                                                type="text"
                                                id="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                placeholder="Nombre/s Apellido/s"
                                                className="mt-1 w-full px-4 py-2 border text-[1.2rem] border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#3B832F] focus:border-[#3B832F]"
                                            />
                                            {errors.name && <p className="text-red-500">{errors.name}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block font-semibold text-[1.5rem] text-[#3B832F]">Correo electrónico</label>
                                            <input
                                                type="email"
                                                id="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                placeholder="correo@email.com"
                                                className="mt-1 w-full px-4 py-2 border text-[1.2rem] border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#3B832F] focus:border-[#3B832F]"
                                            />
                                            {errors.email && <p className="text-red-500">{errors.email}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="phone" className="block font-semibold text-[1.5rem] text-[#3B832F]">Número de teléfono</label>
                                            <input
                                                type="tel"
                                                id="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                placeholder="1133334444"
                                                className="mt-1 w-full px-4 py-2 border text-[1.2rem] border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#3B832F] focus:border-[#3B832F]"
                                            />
                                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="serviceType" className="block font-semibold text-[1.5rem] text-[#3B832F]">Tipo de afiliación</label>
                                            <select
                                                id="serviceType"
                                                value={formData.serviceType}
                                                onChange={handleChange}
                                                className="mt-1 w-full font-regular text-[1.2rem] px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#3B832F] focus:border-[#3B832F]"
                                            >
                                                <option value="">Selecciona una opción</option>
                                                <option className="font-semibold">Afiliado Activo</option>
                                                <option className="font-semibold">Afiliado Adherente</option>
                                                <option className="font-semibold">Empresa, Institución o Comercio</option>
                                            </select>
                                            {errors.serviceType && <p className="text-red-500">{errors.serviceType}</p>}
                                        </div>
                                        <div className="justify-center">
                                            <div>
                                            {successMessage && (
                                                <p className="text-[#58b647] text-center text-[1.5rem] font-bold mb-2">
                                                    {successMessage}
                                                </p>
                                            )}
                                            </div>
                                
                                            <button
                                                type="submit"
                                                className="text-[1.3rem] bg-[#3B832F] px-[1rem] py-[0.7rem] w-full font-medium flex items-center justify-center rounded-[0.7rem] shadow-lg text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3B832F]"
                                                disabled={isLoading}
                                            >
                                                 {isLoading ? <Spinner /> : 'Enviar formulario'}
                                            </button>
                                        </div>
                                    </form>
                                </div> */}
                            </div>
                        </div>
                    </section>
                </main>
            </body>
        </div>
    );
};

export default AffiliatesForm;
