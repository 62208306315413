import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import farmMoron from '../Images/Farmacias/farmMORON-OR.webp'
import farmMerlo from '../Images/Farmacias/farmMER-OR.webp'
import farmMoreno from '../Images/Farmacias/farmMOR-OR.webp'
import farmRamos from '../Images/Farmacias/farmRAM-OR.webp'
import farmLaferrere from '../Images/Farmacias/farmLAFE-OR.webp'
import ubiIcon from '../Images/Icons/ubiICON.svg'


const PharmacyCard = ({ location, title, address, imgSrc, initMap }) => {
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    if (showMap) {
      initMap();
    }
  }, [showMap, initMap]);

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  return (
    <div className="rounded-[1.2rem] h-[50rem] flex flex-col bg-white shadow-2xl" data-aos="fade-up">
      <div className="flex-1 rounded-t-[1.2rem] relative overflow-hidden">
        {showMap ? (
          <div id={`map${location}`} className="h-full w-full transition-all duration-500"></div>
        ) : (
          <img src={imgSrc} alt="Farmacia" className="mr-6 h-full w-full object-cover transition-all duration-500" />
        )}
      </div>
      <div className="p-6">
        <h3 className="text-[#3B832F] text-[2rem] font-bold">{title}</h3>
        <p className="text-[1.5rem] text-[#808080] font-semibold">{address}</p>
      </div>
      <div className="justify-content-start flex p-6">
        <button
          onClick={toggleMap}
          className="text-[1.2rem] w-[10.5rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] relative flex transform items-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]"
        >
          <img src={ubiIcon} className="mr-[1rem] h-7 w-7 transition-transform hover:scale-110" alt="Ubicación Icono" />
          <span>{showMap ? 'Ver foto' : 'Ver mapa'}</span>
        </button>
      </div>
    </div>
  );
};

const Pharmacy = () => {
  const initMapMoron = () => {
    const map = L.map('mapMORON').setView([-34.653960, -58.619034], 16);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
    L.marker([-34.653960, -58.619034]).addTo(map).bindPopup('<b>Amecys Morón</b><br>Int. Norberto García Silva 740, Morón').openPopup();
  };

  const initMapMerlo = () => {
    const map = L.map('mapMER').setView([-34.667753, -58.725351], 16);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
    L.marker([-34.667753, -58.725351]).addTo(map).bindPopup('<b>Amecys Merlo</b><br>Sarandí 576, Merlo').openPopup();
  };

  const initMapMoreno = () => {
    const map = L.map('mapMOR').setView([-34.643710, -59.788372], 16);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
    L.marker([-34.667753, -58.725351]).addTo(map).bindPopup('<b>Amecys Moreno</b><br>Gral. Manuel Belgrano 152, Moreno').openPopup();
  };

  const initMapRamos = () => {
    const map = L.map('mapRAM').setView([-34.643710, -58.566134], 16);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
    L.marker([-34.667753, -58.725351]).addTo(map).bindPopup('<b>Amecys Ramos Mejía</b><br>Rosales 261, Ramos Mejía').openPopup();
  };

  const initMapLaferrere = () => {
    const map = L.map('mapLAFE').setView([-34.749727, -58.587410], 16);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
    L.marker([-34.667753, -58.725351]).addTo(map).bindPopup('<b>Amecys G. De Laferrere</b><br>Olegario Víctor Andrade 6035, Gregorio de Laferrere').openPopup();
  };


  return (
    <div className="container mx-auto mt-8">
      <section className="py-3 mb-[10rem] p-6" data-aos="fade-up">
        <h2 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">Farmacias</h2>
        <p className="mb-[2rem] text-[#808080] text-[1.5rem] md:text-[2rem] font-medium">
          Encontrá la farmacia más cercana a tu ubicación en todo Zona Oeste.
        </p>

        <div className="grid-cols-1 mb-8 grid gap-4 md:grid-cols-2">
          <PharmacyCard
            location="MORON"
            title="Amecys Morón"
            address="Int. Norberto García Silva 740, Morón"
            imgSrc={farmMoron}
            initMap={initMapMoron}
          />
          <PharmacyCard
            location="MER"
            title="Amecys Merlo"
            address="Sarandí 576, Merlo"
            imgSrc={farmMerlo}
            initMap={initMapMerlo}
          />
          <PharmacyCard
            location="MOR"
            title="Amecys Moreno"
            address="Gral. Manuel Belgrano 152, Moreno"
            imgSrc={farmMoreno}
            initMap={initMapMoreno}
          />
          <PharmacyCard
            location="RAM"
            title="Amecys Ramos Mejía"
            address="Rosales 261, Ramos Mejía"
            imgSrc={farmRamos}
            initMap={initMapRamos}
          />
          <PharmacyCard
            location="LAFE"
            title="Amecys Laferrere"
            address="Olegario Víctor Andrade 6035, Gregorio de Laferrere"
            imgSrc={farmLaferrere}
            initMap={initMapLaferrere}
          />
        </div>
      </section>
    </div>
  );
};

export default Pharmacy;
