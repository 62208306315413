import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import rightStroke from '../Images/Icons/rightStroke.svg'
import viewMoreGREEN from '../Images/Icons/viewMore-GREEN.svg'
import AOS from 'aos';
import 'aos/dist/aos.css'; 


const Affiliates = () => {
    
    useEffect(() => {
        AOS.init({
          duration: 1000, 
        });
      }, []); 

  return (
    <div>
    <body className="font-poppins p-6">
    <main className="container mx-auto">
        <section className="py-3" data-aos="fade-up">
            <h1 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">
                Afiliados Activos
            </h1>
        <hr className="mb-[2rem] mt-[1rem]"/>
        <div>
            <div className="mb-4 flex">
            <img src={rightStroke} className="mr-[1rem]"/>
            <p className="text-[#808080] text-[1.5rem] font-medium">Último recibo de haberes.</p>
            </div>
            <div className="mb-4 flex">
                <img src={rightStroke} className="mr-[1rem]"/>
                <p className="text-[#808080] text-[1.5rem] font-medium">Documento Nacional de Identidad (DNI).</p>
            </div>
            <div className="mb-8 flex">
                <img src={rightStroke} className="mr-[1rem]"/>
                <p className="text-[#808080] text-[1.5rem] font-medium">Abonar el 1% para poder tramitar, de lo contrario, deberá esperar que le figure descontado en su recibo de haberes.</p>
            </div>
            <div className="overflow-x-auto">
                    <table className="font-poppins min-w-full rounded-lg bg-white shadow-2xl">
                        <thead>
                            <tr>
                                <th className="border-b-2 leading-4 text-[1.5rem] border-gray-300 bg-gray-200 px-6 py-4 text-left uppercase tracking-wider text-[#808080]">Hijos</th>
                                <th className="border-b-2 leading-4 text-[1.5rem] border-gray-300 bg-gray-200 px-6 py-4 text-left uppercase tracking-wider text-[#808080]">Esposo/a</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">Partida de nacimiento</td>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">Certificado de Matrimonio o Concubinato</td>
                            </tr>
                            <tr className="bg-gray-50">
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">DNI</td>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">DNI</td>
                            </tr>
                            <tr>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">Foto 3x3cm o fotocopia a color del frente del DNI</td>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">Foto 3x3cm o fotocopia a color del frente del DNI</td>
                            </tr>
                        </tbody>
                    </table>
            </div>
            <Link to="/AffiliatesForm" className="text-[1.5rem] text-[#3B832F] border-2 border-[#3B832F] px-4 h-[4rem] w-[17rem] mt-[2rem] rounded-[0.7rem] flex transform items-center justify-center font-medium transition-transform hover:scale-105">
                Enviar formulario
                <img src={viewMoreGREEN} alt="Ver más" className="ml-[1rem] h-5 w-5 transform transition-transform hover:scale-110"/>
            </Link>
        </div>

        <h1 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold mt-[5rem]">
                Afiliados Adherentes
            </h1>
        <hr className="mb-[2rem] mt-[1rem]"/>
        <div>
            <div className="mb-4 flex">
            <img src={rightStroke} className="mr-[1rem]"/>
            <p className="text-[#808080] text-[1.5rem] font-medium">Documento Nacional de Identidad (DNI).</p>
            </div>
            <div className="mb-4 flex">
                <img src={rightStroke} className="mr-[1rem]"/>
                <p className="text-[#808080] text-[1.5rem] font-medium">Servicio a su nombre (con mismo domicilio que en su DNI).</p>
            </div>
            <div className="mb-4 flex">
                <img src={rightStroke} className="mr-[1rem]"/>
                <p className="text-[#808080] text-[1.5rem] font-medium">Foto 3x3cm.</p>
            </div>
            <div className="mb-8 flex">
                <img src={rightStroke} className="mr-[1rem]"/>
                <p className="text-[#808080] text-[1.5rem] font-medium">Cuota mensual de $...</p>
            </div>
            <div className="container mx-auto">
                <div className="overflow-x-auto">
                    <table className="font-poppins min-w-full rounded-lg bg-white shadow-lg">
                        <thead>
                            <tr>
                                <th className="border-b-2 leading-4 text-[1.5rem] border-gray-300 bg-gray-200 px-6 py-4 text-left uppercase tracking-wider text-[#808080]">Hijos</th>
                                <th className="border-b-2 leading-4 text-[1.5rem] border-gray-300 bg-gray-200 px-6 py-4 text-left uppercase tracking-wider text-[#808080]">Esposo/a</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">Partida de nacimiento</td>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">Certificado de Matrimonio o Concubinato</td>
                            </tr>
                            <tr className="bg-gray-50">
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">DNI</td>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">DNI</td>
                            </tr>
                            <tr>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">Foto 3x3cm o fotocopia a color del frente del DNI</td>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">Foto 3x3cm o fotocopia a color del frente del DNI</td>
                            </tr>
                            <tr>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">Cuota mensual de $... pesos</td>
                                <td className="text-[1.2rem] border-b border-gray-300 px-6 py-4">Cuota mensual de $... pesos</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Link to="/AffiliatesForm" className="text-[1.5rem] text-[#3B832F] border-2 border-[#3B832F] px-4 h-[4rem] w-[17rem] mt-[2rem] rounded-[0.7rem] flex transform items-center justify-center font-medium transition-transform hover:scale-105">
                    Enviar formulario
                    <img src={viewMoreGREEN} alt="Ver más" className="ml-[1rem] h-5 w-5 transform transition-transform hover:scale-110"/>
                </Link>
            </div>
        </div>
        </section>

        <section className="py-3 mb-[8rem] mt-[5rem]" data-aos="fade-up">
        <h1 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">
            Empresas, Instituciones o Comercios
        </h1>
        <hr className="mb-[2rem] mt-[1rem]"/>
        <div>
            <div className="mb-4 flex">
            <p className="text-[#808080] text-[1.5rem] font-medium">Si no contas con el convenio firmado, podemos contactarnos para poder realizarlo lo antes posible y que los trabajadores puedan asociarse a la <b>Mutual de Amecys</b> y disfrutar de sus beneficios.</p>
            </div>
            <div className="flex">
                <img src={rightStroke} className="mr-[1rem]"/>
                <p className="text-[1.5rem] md:text-[1.5rem] text-[#808080] font-semibold">
                Dicho convenio no genera gastos para la empresa, institución o comercio firmante.
                </p>
            </div>
            <Link to="/AffiliatesForm" className="text-[1.5rem] text-[#3B832F] border-2 border-[#3B832F] px-4 h-[4rem] w-[17rem] mt-[2rem] rounded-[0.7rem] flex transform items-center justify-center font-medium transition-transform hover:scale-105">
                Enviar formulario
                <img src={viewMoreGREEN} alt="Ver más" className="ml-[1rem] h-5 w-5 transform transition-transform hover:scale-110"/>
            </Link>
        </div>
        </section>
        </main>
    </body>
    </div>
  );
};

export default Affiliates;
