import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Affiliates from './components/Affiliates';
import Optics from './components/Optics';
import Benefits from './components/Benefits';
import Pharmacy from './components/Pharmacy';
import Authorities from './components/Authorities';
import Tourism from './components/Tourism';
import AffiliatesForm from './components/AffiliatesForm';
import Companies from './components/Agreements/Companies';
import Institutions from './components/Agreements/Institutions';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/utils/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Agreements/Companies" element={<Companies />} />
        <Route path="/Agreements/Institutions" element={<Institutions />} />
        <Route path="/Affiliates" element={<Affiliates />} />
        <Route path="/Benefits" element={<Benefits />} />
        <Route path="/Pharmacy" element={<Pharmacy />} />
        <Route path="/Optics" element={<Optics />} />
        <Route path="/Authorities" element={<Authorities />} />
        
        <Route path="/Tourism" element={<Tourism />} />
        <Route path="/AffiliatesForm" element={<AffiliatesForm />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
