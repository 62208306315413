import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AMECYScenter from '../Images/AMECYScenter.svg'
import wppIcon from '../Images/Icons/wppIcon.svg'
import mutualMoronBottom from '../Images/mutualMoronBottom.jpg'
import autoridades from '../Images/autoridades3.jpg'
import tramite from '../Images/ledesma.jpg'
import rightStroke from '../Images/Icons/rightStroke.svg'
import fullrightStroke from '../Images/Icons/fullRightStroke-GREEN.svg'

//autoridades
import Ledesma from '../Images/lp-LEDESMA.webp'
import Luduena from '../Images/LUDUENA.webp'
import Gardella from '../Images/GARDELLA.webp'
import Sotelo from '../Images/SOTELO.webp'

//beneficios
import campos from '../Images/BenefitsImages/campo.webp'
import arm from '../Images/Óptica/arm.webp';
import ortopedia from '../Images/BenefitsImages/ortopedia.webp'
import farmRamos from '../Images/Farmacias/farmRAM-OR.webp'
import cooperativa from '../Images/BenefitsImages/cooperativa2001.webp'
import casamiento from '../Images/BenefitsImages/casamiento.webp'
import escolaridad from '../Images/BenefitsImages/escolaridad.webp'
import nacimiento from '../Images/BenefitsImages/nacimiento.webp'
import construccion from '../Images/BenefitsImages/construccion.webp'
import marquez from '../Images/BenefitsImages/grupoMarquez.webp'
import hotelRel from '../Images/ElReloj/hotel.webp'

const benefits = [
    [1, "Farmacias", "Descuentos en todas las farmacias", farmRamos],
    [2, "Escolaridad", "Todos los años te damos útiles escolares", escolaridad],
    [3, "Hoteles", "Vacaciona en Santa Teresita o Cordoda", hotelRel],
    [4, "Casamiento", "Beneficios exclusivos por casamiento", casamiento],
    [5, "Óptica", "Descuentos en nuestra propia óptica", arm],
    [6, "Nacimiento", "Beneficios exclusivos por nacimiento", nacimiento],
];

const Home = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % benefits.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + benefits.length) % benefits.length);
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, 5000); // Cambia de slide cada 5 segundos
        return () => clearInterval(interval);
    }, []);

    const visibleCards = [
        benefits[(currentIndex - 1 + benefits.length) % benefits.length],
        benefits[currentIndex],
        // benefits[(currentIndex + 1) % benefits.length],
    ];


    return (
        <div>
            <body className="font-poppins">
                <a href="https://api.whatsapp.com/send?phone=5491135208581"
                    className="bg-[#25D366] z-[9998] fixed right-8 bottom-24 transform rounded-full p-3 shadow-2xl transition-transform hover:bg-[#3B832F] hover:scale-110">
                    <img src={wppIcon} alt="WhatsApp" className="h-20 w-20 p-2" />
                </a>
                <main data-aos="fade-up">
                    {/* Presentación */}
                    <section className="w-full py-4 md:py-20 px-4 md:px-8" data-aos="fade-up">
                        <div className="mx-auto flex flex-col md:flex-row items-center">
                            <div className="w-full text-left md:pl-10 lg:pl-20">
                                <h3 className="text-5xl md:text-5xl lg:text-[5rem] text-[#3B832F] pb-3 pt-3 font-extrabold leading-tight" style={{ lineHeight: "1.2" }}>
                                    Asociación Mutual de Empleados de Comercio y Servicios
                                </h3>
                                <p className="mt-6 text-4xl md:text-3xl lg:text-[3rem] text-[#808080] pb-4 font-medium" style={{ lineHeight: "1.1" }}>
                                    Lo mejor para el empleado de comercio.
                                </p>
                            </div>

                            <div className="flex w-full justify-center md:justify-end mt-6 md:mt-0 md:pr-10 lg:pr-20">
                                <img src={AMECYScenter} className="h-[25rem] md:h-[35rem] lg:h-[30rem] object-contain" alt="AMECYS Center" />
                            </div>
                        </div>
                    </section>

                    {/* Datos */}
                    <section className="bg-[#f2f2f2] w-full py-20" data-aos="fade-up">
                        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center w-full gap-16">
                            <div className="w-full text-center md:text-left">
                                <h3 className="text-[3rem] text-[#3B832F] font-extrabold leading-tight">
                                    +8000
                                </h3>
                                <p className="text-[1.5rem] text-[#808080] font-medium leading-tight">
                                    Afiliados que
                                </p>
                                <span className="text-[1.5rem] text-[#808080] font-medium leading-tight">disfrutan de sus beneficios</span>
                            </div>

                            <div className="w-full text-center flex flex-col md:text-left">
                                <h3 className="text-[3.5rem] text-[#3B832F] font-extrabold leading-tight">
                                    +10
                                </h3>
                                <p className="text-[1.5rem] text-[#808080] font-medium leading-tight">
                                    Beneficios exclusivos en
                                </p>
                                <span className="text-[1.5rem] text-[#808080] font-medium leading-tight">salud, turismo, trabajo y farmacias</span>
                            </div>

                            <div className="w-full text-center md:text-left">
                                <h3 className="text-[3rem] text-[#3B832F] font-extrabold leading-tight">
                                    5
                                </h3>
                                <p className="text-[1.5rem] text-[#808080] font-medium leading-tight">
                                    Farmacias distribuidas
                                </p>
                                <span className="text-[1.5rem] text-[#808080] font-medium leading-tight">por todo Zona Oeste</span>
                            </div>
                        </div>
                    </section>

                    {/* Historia */}
                    <section className="w-full py-[10rem] px-8 mb-[5rem] md:mb-[10rem]" data-aos="fade-up">
                        <div className="container mx-auto flex flex-col-reverse items-center md:flex-row">
                            <div className="mr-[5.5rem] w-full text-left md:w-2/3" data-aos="fade-up">
                                <h3 className="text-4xl md:text-[4.5rem] pl-10 text-[#fff] pb-8 pt-3 font-extrabold leading-tight">
                                    Desde 1994, integrando y otorgando
                                    beneficios a nuestros Afiliados
                                </h3>
                                <p className="mb-[1rem] text-2xl md:text-[3rem] pl-10 text-[#91FF80] pb-3 font-bold">La Mutual de todos</p>
                            </div>

                            <div className="absolute inset-0 -z-10">
                                <img src={mutualMoronBottom} alt='Modify' className="h-full w-full object-cover object-bottom" />
                                <div className="absolute inset-0 bg-gradient-to-r from-[#202020] to-transparent"></div>
                                <div className="absolute inset-0 bg-black opacity-25"></div>
                            </div>
                        </div>
                    </section>


                    {/* Beneficios */}
                    <section className="container mx-auto px-4 md:px-8 py-12 mb-[5rem] md:mb-[10rem]" data-aos="fade-up">
                        <div className="flex justify-center text-center items-center mb-8">
                            <Link to="/Benefits" className="text-[1.8rem] md:text-[2rem] flex items-center text-center font-bold text-[#3B832F] transition-transform hover:scale-105">
                                Ver todos los Beneficios
                                <img src={fullrightStroke} className="ml-4 h-6 w-6 md:h-8 md:w-8" alt="Arrow Icon" />
                            </Link>
                        </div>
                        <h2 className="text-3xl md:text-[4rem] text-[#808080] mb-4 items-center text-center font-bold leading-tight tracking-tight px-4 md:px-[8rem]">
                            ¡Ofrecemos beneficios en salud, trabajo, economía y más!
                        </h2>
                        <h2 className="text-2xl md:text-[2rem] text-[#808080] mb-[5rem] items-center text-center font-regular leading-tight tracking-tight px-4 md:px-[8rem]">
                            Amecys brinda más de 10 beneficios exclusivos
                        </h2>
                        <div className="relative w-full max-w-10xl mx-auto px-4 py-8">
                            <div className="flex justify-center items-center space-x-4 md:space-x-12">
                                {visibleCards.map(([id, title, description, image], index) => (
                                    <div
                                        key={id}
                                        className={`h-[20rem] md:h-[35rem] relative w-full overflow-hidden rounded-2xl shadow-2xl hover:scale-110 hover:shadow-2xl transition-all duration-300'
                                            }`}
                                    >
                                        <img src={image} alt={title} className="absolute inset-0 h-full w-full object-cover" />
                                        <div className="absolute inset-0 bg-gradient-to-t from-[#202020] to-transparent"></div>
                                        <div className="absolute top-0 p-2 md:p-8">
                                            <h1 className="text-[0.6rem] md:text-[1.4rem] rounded-lg p-[0.5rem] text-[#fff] bg-[#58b647] mb-2 font-medium">
                                                Beneficio
                                            </h1>
                                        </div>
                                        <div className="absolute bottom-0 w-full p-4 md:p-8 text-white">
                                            <h1 className="text-[1rem] md:text-[2.4rem] text-[#58b647] font-bold">
                                                {title}
                                            </h1>
                                            <p className="text-[0.6rem] md:text-[1.4rem] font-medium">
                                                {description}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <button
                                onClick={prevSlide}
                                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#58b647] p-4 rounded-full shadow-md transition-trasform duration-300 hover:scale-110 hover:bg-[#3B832F]"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#fff" className="h-4 w-4 md:h-8 md:w-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                            <button
                                onClick={nextSlide}
                                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#58b647] p-4 rounded-full shadow-md transition-trasform duration-300 hover:scale-110 hover:bg-[#3B832F]"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#fff" className="h-4 w-4 md:h-8 md:w-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        </div>
                    </section>

                    {/* Autoridades */}
                    <section className="w-full py-[14rem] relative mb-[10rem] p-4" data-aos="fade-up">
                        <div className="container mx-auto flex flex-col-reverse items-center">
                            <div className="w-full text-center justify-center md:w-2/3" data-aos="fade-up">
                                <h3 className="text-5xl md:text-[4.5rem] text-[#fff] pb-3 pt-3 font-extrabold leading-tight">
                                    Consejo Directivo
                                </h3>
                                <p className="text-3xl md:text-[3rem] text-[#91FF80] pb-3 font-bold">Nuestras autoridades lo hacen posible.</p>
                            </div>
                        </div>

                        <div className="absolute inset-0 -z-10">
                            <img src={autoridades} className="h-full w-full object-cover object-bottom" />
                            <div className="absolute inset-0 bg-black opacity-55"></div>
                        </div>
                    </section>


                    {/* Trámites */}
                    <section className="mb-[10rem] w-full py-12 p-4" data-aos="fade-up">
                        <div className="container mx-auto flex flex-col items-center md:flex-row">
                            <div className="flex w-full md:w-1/2 mb-6 md:mb-0 justify-center md:justify-start">
                                <img src={tramite} className="w-[90%] max-w-[800px] rounded-lg shadow-2xl" />
                            </div>

                            <div className="w-full md:w-1/2 pl-6">
                                <h3 className="text-4xl md:text-[4rem] text-[#3B832F] pb-3 pt-3 font-extrabold leading-tight">
                                    Tramitá tu afiliación
                                    fácil y rápido
                                </h3>
                                <div className="mb-2 flex">
                                    <img src={rightStroke} className="mr-[1rem]" />
                                    <p className="text-[#808080] text-2xl md:text-[2rem] font-medium mb-2 mt-2">Trámites sencillos y sin complicaciones.</p>
                                </div>
                                <div className="mb-4 flex">
                                    <img src={rightStroke} className="mr-[1rem]" />
                                    <p className="text-[#808080] text-2xl md:text-[2rem] font-medium">Mínimos requisitos para afiliarte.</p>
                                </div>
                                <p className="mb-[1rem] text-2xl md:text-[1.5rem] text-[#808080] pb-3 font-medium">
                                    Buscamos facilitar tus trámites para que te conviertas en afiliado y accedas a los mejores beneficios.
                                </p>

                                <div>
                                    <Link
                                        to="/Affiliates"
                                        className="text-[1.2rem] bg-[#3B832F] px-[1rem] py-[0.7rem] w-[11.2rem] rounded-[0.7rem] mt-4 flex transform items-center text-left font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]"
                                    >
                                        Más información
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </body>

        </div>
    );
}

export default Home;