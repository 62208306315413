import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import rightStroke from '../Images/Icons/rightStroke.svg'
import leftStroke from '../Images/Icons/fullLeftStroke-GREEN.svg'
import ubiIcon from '../Images/Icons/ubiICON.svg'

import optica from '../Images/Óptica/optica.png';
import arm from '../Images/Óptica/arm.webp';

import Carousel from '../components/utils/Carousel';

function Optics() {

    useEffect(() => {
        AOS.init({
          duration: 1000, 
        });
      }, []); 

  return (
    <div>
        <body class="font-poppins p-6">
            <main class="container mx-auto" data-aos="fade-up">
                <h2 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">
                    Óptica de Amecys
                </h2>
                <p className="mb-[2rem] text-[#808080] text-[1.5rem] md:text-[2rem] font-medium">Siendo afiliado, podes visitar nuestra própia óptica y obtener descuentos.</p>

            <section class="mb-[35rem] mt-[2rem]" data-aos="fade-up">
            <div className="container mx-auto flex flex-col-reverse items-center">
                <div className="absolute inset-0 -z-10">
                    <Carousel />
                </div>
            </div>
            </section>

            <section data-aos="fade-up">
                {/* <div className='mt-[2rem]'>
                    <h1 className="text-[2.5rem] text-[#3B832F] flex items-center font-bold">
                        El Reloj
                    </h1>
                </div> */}
                    <p className="text-[1.5rem] text-[#808080] flex items-center font-medium mb-8">
                    En nuestra óptica, nos enorgullecemos de contar con equipos técnicos especializados de última tecnología para ofrecerle a nuestros afiliados una atención de calidad. Uno de los dispositivos más avanzados que utilizamos es el autorefractómetro, que nos permite detectar problemas como la miopía, hipermetropía y astigmatismo.
                    </p>

                    <div className='flex mb-8'>
                        {/* <img src={rightStroke} className="mr-[1rem]"/> */}
                        <p class="text-[#808080] text-[1.5rem] font-regular">
                        Si sos afiliado, te invitamos a pasar por nuestra óptica y aprovechar los descuentos exclusivos que tenemos para vos. Queremos cuidarte y por eso ofrecemos beneficios especiales para nuestros afiliados.</p>
                    </div>

                    <div className='flex mb-8 text-[1.5rem]'>
                            <img src={rightStroke} className="mr-[1rem]"/>
                            <p class="text-[#808080] font-regular"><strong class="text-[#3B832F]">Ubicación:</strong> Olegario Víctor Andrade 6033, Gregorio de Laferrere, Zona Oeste.</p>
                    </div>

                    <div className='flex mb-[8rem] text-[1.5rem]'>
                        <img src={rightStroke} className="mr-[1rem]"/>
                        <p class="text-[#808080] font-regular">¿Queres saber como afiliarte? <Link to="/Affiliates" className='font-semibold underline text-[#3B832F]'>Hace click acá para informarte.</Link></p>
                    </div>
            </section>

            {/* <div className="container mx-auto px-4 mb-[8rem]">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="flex flex-col items-start">
                    <div className="w-full h-[35rem] flex items-center justify-center mb-4">
                        <img src={optica} className='w-full h-full object-cover rounded-2xl'/>
                    </div>
                    <h1 className="text-[2rem] text-[#3B832F] flex items-center font-medium">Óptica</h1>
                    <p className="text-[1.5rem] text-[#808080] flex items-center font-medium mb-8">
                    Si sos afiliado, te invitamos a pasar por nuestra óptica y aprovechar los descuentos exclusivos que tenemos para vos. Queremos cuidarte y por eso ofrecemos beneficios especiales para nuestros afiliados.
                    </p>
                    </div>

                    <div className="flex flex-col items-start">
                    <h1 className="text-[2rem] text-[#3B832F] flex items-center font-medium">Equipo ARM</h1>
                    <p className="text-[1.5rem] text-[#808080] flex items-center font-medium mb-8">
                        En nuestra óptica, nos enorgullecemos de contar con equipos técnicos especializados de última tecnología para ofrecerle a nuestros afiliados una atención de calidad. Uno de los dispositivos más avanzados que utilizamos es el autorefractómetro, que nos permite detectar problemas como la miopía, hipermetropía y astigmatismo.
                    </p>
                    <div className="w-full h-[35rem] flex items-center justify-center mb-4">
                        <img src={arm} className='w-full h-full object-cover rounded-2xl'/>
                    </div>
                    </div>
                </div>
            </div> */}
            </main>
        </body>
    </div>
  )
}

export default Optics
