import React, { useEffect } from 'react';
import rightStroke from '../../Images/Icons/rightStroke.svg'
import AOS from 'aos';
import 'aos/dist/aos.css'; 


const Companies = () => {
    
    useEffect(() => {
        AOS.init({
          duration: 1000, 
        });
      }, []); 

  return (
    <div>
        <body className="font-poppins p-6">
        <main className="container mx-auto">
            <section className="py-3 mb-[8rem]" data-aos="fade-up">
            <h1 className="text-[2.5rem] md:text-[4rem] text-[#3B832F] flex items-center font-bold">
                Convenios Empresariales
            </h1>
            <p className="mb-[2rem] text-[#808080] text-[1.5rem] md:text-[2rem] font-medium">
                Estas son todas las empresas que confían y disfrutan de los beneficios de Amecys.
            </p>
            <hr className="mb-[2rem] mt-[1rem]" />
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="w-full md:w-2/3">
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SERMEX</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">APERSEG</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SOM (CARMODY JORGE ALBERT)</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">ADHERENTE</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">ROXANA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">HERCOLANOS Y HNOS. L</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MAMONE</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DAURIA DANIEL</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LAS MARGARITAS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SODIMAC</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">COPPEL</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">EL OMBU</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">OSECAC</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CENCOSUD</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">S.E.O.C.A</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">VITECSO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SALUD PROTEGIDA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">EUKASTEL</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CONURBANO DISTRIBUCIÓN</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">NORAUTO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">VARIAS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">STEPAN SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LEKATOR</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">JULIO RUBEN LEDESMA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DISTRIBUIDORA DON JUAN</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">FAECYS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LIBRERIA L Y M</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">RECURSOS Y GESTIÓN</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">PECADO CAPITAL</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">JAVA SAPREME</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DISTRIBUIDORA EL GALLEGO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">EL GALLEGO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">AMI MUSICA SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">EL ABASTECEDOR</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">GODY</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CAMPS FERNANDO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CAMPS MATILDE</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LA CELADA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SE¥OR GONZALEZ </p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MASCOTAS SAN MIGUEL</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LAS PALMAS</p>
                </div>
                <div className='flex'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DELIA FLORINDA PAEZ</p>
                </div>
            </div>

            <div className="w-full md:w-2/3">
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MAXICONSUMO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DIARCO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">UNO SUPERMERCADO SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">ARON-RABE</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CAROMAR</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">BASANI</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MEDAMAX</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">PUCARA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">TRANSPORTE ANEXO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">INC. SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">IFCO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LIMART</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">JJ DEPORTES</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MODUL TECNICS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CABA FOOD</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">IGUAZU HOGAR</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">FORTI</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DISTRILO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">INSTITUTO SAN LUIS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MARIA GRASSO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LOS 3 ALGARROBOS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">IAM</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">NEUMÁTICOS DEL SUR</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">FELER GROUP SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CASA ALEMAR</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DELEGADOS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CHUEKOS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DIMAGOL</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SPORT LINE</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MILANO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">PDV</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CORVEN</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">UNILEVER</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">AMMA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MATERIALES HAEDO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MERCHAMPS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SWISS JUST / GARCIA FEDERICO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SALES POWER SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">FLORES HOGAR</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">ADHERENTE JAVA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">GEMA INDUMENTARIA</p>
                </div>
                <div className='flex'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LA MARDIAL</p>
                </div>
            </div>

            <div className="w-full md:w-2/3">
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DORINKA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">EL ECONÓMICO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">AMECYS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DIA %</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DISALAR</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">ALESSANDRINI</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LAURIA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CASTILLA DISTRIBUIDORA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DISCO SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">JUMBO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MAKRO SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MAYCAR SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DUBOIS NESTOR</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">TREOLAND SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">ARGEENPOOL</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CERALDI</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MELE Y CIA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">GUALDESI HNOS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">TMT</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">FERREYRA JOSE</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">FALABELLA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MOHA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">BRENBRIAN SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LADA SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">ORESANO</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">GRIMOLDI</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">VICENTI</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">COTO CICSA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">FRAVEGA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">DISE¥O GROUP</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">COMESTIBLE CLAMIDAFE</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">PROMOSTAR</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">RECONSTRUCCIÓN CA¥OS SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">CONCEPTO SUSTENTABLE SA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LA PI¥ATA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LIDERCAR</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SALES ACTIONS</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SALAMANCA DISTRIBUIDORA</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">LA CODE</p>
                </div>
                <div className='flex mb-4'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">SOLVENS SERVIC. ESPEC.</p>
                </div>
                <div className='flex'>
                    <img src={rightStroke} className="mr-[1rem]"/>
                    <p className="text-[#808080] text-[1.5rem] font-medium">MAN POWER</p>
                </div>
            </div>
        </div>
     </section>
        </main>
    </body>
    </div>
  );
};

export default Companies;
