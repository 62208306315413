import React from 'react';
import { Link } from 'react-router-dom';
import wppIcon from '../Images/Icons/wppIcon.svg'
import AMECYSnobg from '../Images/AMECYSnobg.png'

const Footer = () => {
  return (
    <footer className="bg-[#f8f8f8] font-poppins text-[#808080] py-12">
    <div className="mx-auto max-w-7xl px-8 sm:px-6 lg:px-8">
        <div className="grid-cols-1 grid gap-12 md:grid-cols-3">
            <div className='mr-[8rem]'>
                <img src={AMECYSnobg} className='w-[25rem] mb-8'/>
                <p className="text-[1.5rem] mt-4 font-semibold">
                    Mutual Amecys: <span className="text-[1.5rem] font-light"> Int. Norberto García Silva 535, Morón</span>
                </p>
            </div>

            <div>
                    <h3 className="text-[1.5rem] font-poppins mb-4 font-bold">Enlaces Rápidos</h3>
                <ul>
                    <li className="mb-2">
                            <Link to="/" className="font-poppins text-[#a4a4a4] font-poppins text-[1.5rem] font-medium hover:text-[#808080] hover:text-inherit">Inicio</Link>
                    </li>
                    <li className="mb-2">
                            <Link to="/Agreements/Companies" className="font-poppins text-[#a4a4a4] font-poppins text-[1.5rem] font-medium hover:text-[#808080] hover:text-inherit">Convenios Empresariales</Link>
                    </li>
                    <li className="mb-2">
                            <Link to="/Agreements/Institutions" className="font-poppins text-[#a4a4a4] font-poppins text-[1.5rem] font-medium hover:text-[#808080] hover:text-inherit">Convenios Institucionales</Link>
                    </li>
                    <li className="mb-2">
                            <Link to="/Affiliates" className="font-poppins text-[#a4a4a4] font-poppins text-[1.5rem] font-medium hover:text-[#808080] hover:text-inherit">Afiliación</Link>
                    </li>
                    <li className="mb-2">
                            <Link to="/Benefits" className="font-poppins text-[#a4a4a4] font-poppins text-[1.5rem] font-medium hover:text-[#808080] hover:text-inherit">Beneficios</Link>
                    </li>
                    <li className="mb-2">
                            <Link to="/Pharmacy" className="font-poppins text-[#a4a4a4] font-poppins text-[1.5rem] font-medium hover:text-[#808080] hover:text-inherit">Farmacias</Link>
                    </li>
                    <li className="mb-2">
                            <Link to="/Optics" className="font-poppins text-[#a4a4a4] font-poppins text-[1.5rem] font-medium hover:text-[#808080] hover:text-inherit">Óptica</Link>
                    </li>
                    <li className="mb-2">
                            <Link to="/Authorities" className="font-poppins text-[#a4a4a4] font-poppins text-[1.5rem] font-medium hover:text-[#808080] hover:text-inherit">Autoridades</Link>
                    </li>
                </ul>
            </div>

            <div>
                    <h3 className="text-[2rem] font-poppins mb-4 font-bold">¿Necesitas ayuda?</h3>
                    <p className="font-poppins text-[1.5rem] font-medium">
                        ¡Contactanos ahora mismo a nuestro WhatsApp!
                    </p>
                    <div className='flex text-center items-center justify-center'>
                    <a href="https://api.whatsapp.com/send?phone=5491135208581" className="text-[1.2rem] bg-[#3B832F] px-[1rem] py-[0.7rem] rounded-[0.7rem] justify-center relative mt-4 flex w-full transform items-center text-center font-medium text-white transition-transform hover:scale-105 hover:bg-[#2f6824]">
                        <img src={wppIcon} alt="Ver más" className="mr-[1rem] h-6 w-6 transform transition-transform hover:scale-110"/>
                        Nuestro WhatsApp
                    </a>
                    </div>
            </div>
        </div>

        <div className="border-[#f1f1f1] mt-8 flex flex-col items-center justify-between border-t pt-6 md:flex-row">
            <div className="flex space-x-6">
                    <a href="https://api.whatsapp.com/send?phone=5491135208581" className="cursor-pointer">
                    <svg className="h-8 w-8" fill="#808080" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path d="M500.4,241.8c-0.6,63.4-16.9,113-50.8,156.1c-36.4,46.3-83.8,75.7-141.8,87.6
                    c-44.8,9.1-88.4,5.1-131.2-10.4c-9.5-3.5-18.8-7.6-27.7-12.5c-2.7-1.5-5-1.7-8-0.7c-40.5,13.1-81.1,26.1-121.7,39
                    c-1.8,0.6-4.2,2.3-5.6,1c-1.6-1.6,0.4-4,1-5.9c8.1-24.1,16.2-48.3,24.4-72.4c5-14.7,9.8-29.3,15-43.9c1.4-3.8,0.9-6.7-1.2-10.2
                    c-10.3-17.6-17.9-36.4-23.3-56.1c-10.1-37-11.2-74.4-3.3-111.8c8.6-40.8,26.6-77.2,54.3-108.6c33.5-38,74.8-63.1,123.9-75.2
                    c31.3-7.7,62.9-8.9,94.7-3.7c41.6,6.8,79.1,23.3,111.8,49.9c34,27.7,58.8,62.1,74.4,103.4C493.9,189.7,500.7,226.2,500.4,241.8z
                    M74.6,441.5c24.8-8,48.5-15.6,72-23.3c3.1-1,5.6-0.8,8.3,1c9,6,18.7,10.8,28.7,15c32.5,13.8,66.3,18.8,101.2,14.3
                    c42.8-5.5,80.4-22.7,112-52.6c39.8-37.7,61.1-83.9,63-138.5c1.8-53.5-15.4-100.5-51.3-140.4c-34.1-37.9-76.9-59.5-127.4-64.9
                    c-43.9-4.7-85,4.8-123,27.6c-32.5,19.6-57.4,46.3-74.7,80c-19.1,37.1-25.6,76.5-20.1,117.8c4.1,31.2,15.3,59.9,33.3,85.7
                    c2,2.9,2.6,5.3,1.4,8.7c-3.7,10.2-7,20.5-10.5,30.7C83.3,415.2,79.2,427.8,74.6,441.5z" />
                            <path d="M141,194.7c0.5-23.8,8.9-41.5,24.8-55.6c5.1-4.6,11.4-6.6,18.5-5.6c3,0.4,6,0.7,9,0.5
                    c5.6-0.3,9.4,2.4,11.3,7.3c6.7,17.4,13.2,34.8,19.7,52.3c2.2,6-1.6,10.7-4.8,15c-3.7,5.1-8.2,9.6-12.6,14.1
                    c-4.5,4.6-5.1,7.2-2,12.8c18.1,32.9,43.5,58.2,78,73.8c1.2,0.5,2.4,1.2,3.6,1.8c4.6,2.1,8.7,1.6,12.2-2.4
                    c6.4-7.5,13.4-14.4,19.3-22.3c3.9-5.2,6.6-6.1,12.5-3.5c17.6,7.6,34.1,17.2,50.9,26.5c3.1,1.7,4.2,4.3,4.1,7.9
                    c-0.9,29.5-17.8,44.9-47.5,51.7c-14.4,3.3-27.7-0.3-40.9-5.1c-27.7-10-54.4-21.8-76.8-41.6c-17.8-15.8-33.5-33.4-46.8-53.1
                    c-10.2-15.2-20.8-30.1-26.7-47.8C143.4,212,140.9,202.4,141,194.7z" />
                        </g>
                    </svg>
                </a>

                <a href="https://www.instagram.com/amecysmutual/" className="cursor-pointer">
                    <svg className="h-8 w-8" fill="#808080" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                        <g id="_x37__stroke">
                            <g id="Instagram_1_">
                                <rect clip-rule="evenodd" fill="none" fill-rule="evenodd" height="128" width="128" />
                                <path clip-rule="evenodd" fill="#808080" fill-rule="evenodd" id="Instagram" d="M105.843,29.837
                                c0,4.242-3.439,7.68-7.68,7.68c-4.241,0-7.68-3.438-7.68-7.68c0-4.242,3.439-7.68,7.68-7.68
                                C102.405,22.157,105.843,25.595,105.843,29.837z M64,85.333c-11.782,0-21.333-9.551-21.333-21.333
                                c0-11.782,9.551-21.333,21.333-21.333c11.782,0,21.333,9.551,21.333,21.333C85.333,75.782,75.782,85.333,64,85.333z
                                M64,31.135c-18.151,0-32.865,14.714-32.865,32.865c0,18.151,14.714,32.865,32.865,32.865c18.151,0,32.865-14.714,32.865-32.865
                                C96.865,45.849,82.151,31.135,64,31.135z M64,11.532c17.089,0,19.113,0.065,25.861,0.373c6.24,0.285,9.629,1.327,11.884,2.204
                                c2.987,1.161,5.119,2.548,7.359,4.788c2.24,2.239,3.627,4.371,4.788,7.359c0.876,2.255,1.919,5.644,2.204,11.884
                                c0.308,6.749,0.373,8.773,0.373,25.862c0,17.089-0.065,19.113-0.373,25.861c-0.285,6.24-1.327,9.629-2.204,11.884
                                c-1.161,2.987-2.548,5.119-4.788,7.359c-2.239,2.24-4.371,3.627-7.359,4.788c-2.255,0.876-5.644,1.919-11.884,2.204
                                c-6.748,0.308-8.772,0.373-25.861,0.373c-17.09,0-19.114-0.065-25.862-0.373c-6.24-0.285-9.629-1.327-11.884-2.204
                                c-2.987-1.161-5.119-2.548-7.359-4.788c-2.239-2.239-3.627-4.371-4.788-7.359c-0.876-2.255-1.919-5.644-2.204-11.884
                                c-0.308-6.749-0.373-8.773-0.373-25.861c0-17.089,0.065-19.113,0.373-25.862c0.285-6.24,1.327-9.629,2.204-11.884
                                c1.161-2.987,2.548-5.119,4.788-7.359c2.239-2.24,4.371-3.627,7.359-4.788c2.255-0.876,5.644-1.919,11.884-2.204
                                C44.887,11.597,46.911,11.532,64,11.532z M64,0C46.619,0,44.439,0.074,37.613,0.385C30.801,0.696,26.148,1.778,22.078,3.36
                                c-4.209,1.635-7.778,3.824-11.336,7.382C7.184,14.3,4.995,17.869,3.36,22.078c-1.582,4.071-2.664,8.723-2.975,15.535
                                C0.074,44.439,0,46.619,0,64c0,17.381,0.074,19.561,0.385,26.387c0.311,6.812,1.393,11.464,2.975,15.535
                                c1.635,4.209,3.824,7.778,7.382,11.336c3.558,3.558,7.127,5.746,11.336,7.382c4.071,1.582,8.723,2.664,15.535,2.975
                                C44.439,127.926,46.619,128,64,128c17.381,0,19.561-0.074,26.387-0.385c6.812-0.311,11.464-1.393,15.535-2.975
                                c4.209-1.636,7.778-3.824,11.336-7.382c3.558-3.558,5.746-7.127,7.382-11.336c1.582-4.071,2.664-8.723,2.975-15.535
                                C127.926,83.561,128,81.381,128,64c0-17.381-0.074-19.561-0.385-26.387c-0.311-6.812-1.393-11.464-2.975-15.535
                                c-1.636-4.209-3.824-7.778-7.382-11.336c-3.558-3.558-7.127-5.746-11.336-7.382c-4.071-1.582-8.723-2.664-15.535-2.975
                                C83.561,0.074,81.381,0,64,0z" />
                            </g>
                        </g>
                    </svg>
                </a>
            </div>

            <p className="text-[#808080] mt-6 font-medium md:mt-0">&copy; 2024 Amecys - <span className="text-[#808080] mt-6 font-light md:mt-0">Todos los derechos reservados</span></p>
        </div>
    </div>
</footer>
  );
};

export default Footer;
